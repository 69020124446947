import React from 'react'
import RoundsTable from './RoundsTable'

export default class Rounds extends React.Component {

    render() {
        return <div>
            <h1>Rounds</h1>
            <RoundsTable />
        </div>
    }
}